import React, { useState, useCallback } from "react";
import Confirm from "./Confirm";
import Dropdown from "./Dropdown";
import { isString } from "../../utils/isString";
import { css } from "glamor";
import {
  uiColors,
  spacing,
  typographyColors,
  decorationColors,
  colors,
} from "./theme";
import { IoIosClose } from "react-icons/io";

const confirmationHeaderWrapper = css({
  display: "flex",
  gap: spacing.s,
  alignItems: "center",
  justifyContent: "space-between",
  color: typographyColors.defaultBody,
  padding: `${spacing.s} ${spacing.s} ${spacing.s} ${spacing.m}`,
  fontSize: 16,
  fontWeight: "bolder",
  marginTop: spacing.xxs,
});
const closeClass = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: spacing.xs,
  borderRadius: "50%",
  color: uiColors.border,
  cursor: "pointer",
  ":hover": {
    color: typographyColors.defaultBody,
    backgroundColor: "#00000020",
  },
});

export default function DropdownItem({
  confirmationSize = "mini",
  confirmationHeader = "Atenção!",
  confirmationText,
  confirmButton = "Confirmar",
  cancelButton = "Cancelar",
  confirmColor = decorationColors.decorationPurple,
  onClick,
  ...rest
}) {
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const toogleOpenConfirmationHandler = useCallback(() => {
    setOpenConfirmation(!openConfirmation);
  }, [openConfirmation]);

  const confirmationHandler = useCallback(() => {
    onClick();
    setOpenConfirmation(false);
  }, [onClick]);

  const confirmationClass = css({
    backgroundColor: uiColors.fieldBackground,
    ".ui.modal>.content": {
      color: `${typographyColors.defaultBody} !important`,
      padding: `${spacing.s} ${spacing.s} ${spacing.s} ${spacing.m} !important`,
    },
    ".ui.modal>.actions": {
      borderTop: "none !important",
      backgroundColor: `${uiColors.fieldBackground} !important`,
      padding: `${spacing.m} ${spacing.s} ${spacing.m} ${spacing.m}`,
      "> .ui.primary.button": {
        backgroundColor: `${confirmColor} !important`,
        color: "white !important",
        border: "none !important",
        ":hover": {
          backgroundColor: `${confirmColor}DD !important`,
          color: "white !important",
          border: "none !important",
        },
      },
      "> .ui.button": {
        backgroundColor: `${colors.white} !important`,
        color: "black !important",
        border: `2px solid ${colors.greyLight}`,
        ":hover": {
          backgroundColor: `${colors.greyLighter} !important`,
          color: "black !important",
          border: `2px solid ${colors.greyLight}`,
        },
      },
    },
  });

  return (
    <>
      {confirmationText && (
        <Confirm
          open={openConfirmation}
          className={confirmationClass}
          onCancel={toogleOpenConfirmationHandler}
          onConfirm={confirmationHandler}
          header={
            isString(confirmationHeader) ? (
              <div className={confirmationHeaderWrapper}>
                <span>{confirmationHeader}</span>
                <div
                  onClick={toogleOpenConfirmationHandler}
                  className={closeClass}
                >
                  <IoIosClose size={22} />
                </div>
              </div>
            ) : (
              confirmationHeader
            )
          }
          content={confirmationText}
          size={confirmationSize}
          confirmButton={confirmButton}
          cancelButton={cancelButton}
        />
      )}
      <Dropdown.Item
        {...rest}
        onClick={confirmationText ? toogleOpenConfirmationHandler : onClick}
      />
    </>
  );
}
