import { css } from "glamor";
import React from "react";
import { BsBook, BsGear } from "react-icons/bs";
import {
  FaAngleDoubleRight,
  FaMoneyCheckAlt,
  FaRegQuestionCircle,
} from "react-icons/fa";
import { HiOutlineDesktopComputer, HiUsers } from "react-icons/hi";
import { ImDrawer } from "react-icons/im";
import { IoPersonOutline } from "react-icons/io5";
import { PiCertificateLight } from "react-icons/pi";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { matchPath } from "react-router-dom";
import Menu from "../library/Menu";
import MenuItem from "../library/MenuItem";
import AccordionMenu from "../library/AccordionMenu";
import { AiOutlineLock } from "react-icons/ai";
import { spacing } from "../library/theme";

const container = css({
  height: "calc(100vh - 70px)",
  width: 270,
  display: "flex",
  flexDirection: "column",
  padding: `78px ${spacing.s} 0px ${spacing.s}`,
});
const mobileClass = css({
  height: "100vh",
  marginTop: 0,
});
const menuItemClass = css({
  lineHeight: 1.3,
});

export default function MainMenu(props) {
  const { location, mobile, hasInvoices, currentUser, communities } = props;
  const currentPath = location.pathname;
  const className = css(container, mobile && mobileClass);

  return (
    <Menu className={className} vertical>
      <MenuItem
        to="/"
        active={!!matchPath(currentPath, { path: "/", exact: true })}
        label="Meus Cursos"
        icon={HiOutlineDesktopComputer}
      />
      {communities.length > 0 && (
        <AccordionMenu
          active={
            !!matchPath(currentPath, {
              path: "/communities/:communityId",
            })
          }
          label="Comunidade"
          disabled={!communities?.length}
          to={`/communities/${communities[0]?.id}`}
          icon={HiUsers}
        >
          {communities.map((community) => (
            <MenuItem
              className={menuItemClass}
              to={`/communities/${community.id}`}
              active={
                !!matchPath(currentPath, {
                  path: `/communities/${community.id}`,
                  exact: true,
                })
              }
              disabled={!community.hasAccess}
              disabledIcon={<AiOutlineLock size={22} />}
              label={community.name}
            />
          ))}
        </AccordionMenu>
      )}
      <MenuItem
        to="/certificates"
        active={
          !!matchPath(currentPath, { path: "/certificates", exact: true })
        }
        label="Meus certificados"
        icon={PiCertificateLight}
      />
      <MenuItem
        to="/profile/edit"
        active={
          !!matchPath(currentPath, {
            path: ["/profile/edit", "/password/edit"],
            exact: true,
          })
        }
        label="Meu Perfil"
        icon={IoPersonOutline}
      />
      <MenuItem
        to="/support"
        active={!!matchPath(currentPath, { path: "/support", exact: true })}
        label="Suporte"
        icon={FaRegQuestionCircle}
      />
      {hasInvoices && (
        <MenuItem
          to="/invoices"
          active={!!matchPath(currentPath, { path: "/invoices", exact: true })}
          label="Notas Fiscais"
          icon={FaMoneyCheckAlt}
        />
      )}
      <MenuItem
        to="/faqs"
        active={!!matchPath(currentPath, { path: "/faqs", exact: true })}
        label="Perguntas Frequentes"
        icon={BsBook}
      />
      <MenuItem
        to="/contracts"
        active={!!matchPath(currentPath, { path: "/contracts", exact: true })}
        label="Contratos"
        icon={ImDrawer}
      />
      {currentUser?.document && (
        <MenuItem
          as="a"
          target="_blank"
          href={`https://checkouts.ibft.app/meus-pagamentos?doc_number=${currentUser.document}&email=${currentUser.email}`}
          label="Financeiro"
          icon={RiMoneyDollarCircleLine}
        />
      )}
      {currentUser?.apoloAccessEnabled && (
        <MenuItem
          target="_blank"
          href={`https://cbtrg.com.br/login`}
          label="Acessar CITRG"
          icon={FaAngleDoubleRight}
        />
      )}
      {currentUser && currentUser?.isAdmin && (
        <MenuItem href="/manager" label="Ir para o manager" icon={BsGear} />
      )}
    </Menu>
  );
}
