import { css } from "glamor";
import React from "react";
import { FaSignOutAlt } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { Grid, Image } from "semantic-ui-react";

import Logo from "../library/images/logo-inverse.svg";
import Link from "../library/Link";
import {
  buttonColors,
  spacing,
  styles,
  typographyColors,
  uiColors,
} from "../library/theme";

const layout = css(styles.shadow, {
  display: "flex",
  backgroundColor: uiColors.backgroundDark,
  position: "fixed",
  width: "100%",
  height: 70,
  top: 0,
  left: 0,
  zIndex: 3,
});
const signOutLink = css({
  color: typographyColors.inverseBody,
  display: "inline-flex",
  justifyContent: "flex-end",
  alignItems: "center",
  cursor: "pointer",
  "&:hover": { color: typographyColors.inverseBody, opacity: 0.7 },
});
const signOutIconStyle = { marginRight: spacing.xxs };
const rowStyle = { paddingTop: 0, paddingBottom: 0 };
const noPadding = { paddingLeft: spacing.m, paddingRight: spacing.m };
const logo = css({ "& + &": { marginLeft: spacing.m } });
const logoWrapper = css({
  width: "100%",
  display: "flex",
  "@media(max-width: 990px)": {
    justifyContent: "center",
    alignItems: "center",
  },
});
const hamburguerStyle = {
  marginTop: spacing.xs,
};

export default function Header(props) {
  const { toogleSidebar, signOutHandler, currentUser } = props;
  return (
    <Grid
      columns="equal"
      verticalAlign="middle"
      className={layout.toString()}
      padded
    >
      <Grid.Row style={rowStyle}>
        <Grid.Column only="mobile tablet" mobile={3}>
          {currentUser?.updated && (
            <GiHamburgerMenu
              onClick={toogleSidebar}
              size="30"
              color={buttonColors.textPrimary}
              style={hamburguerStyle}
            />
          )}
        </Grid.Column>
        <Grid.Column
          mobile={10}
          computer={3}
          textAlign="left"
          style={noPadding}
        >
          <Link to="/" className={logoWrapper}>
            <Image src={Logo} size="small" className={logo.toString()} />
          </Link>
        </Grid.Column>
        <Grid.Column computer={13} tablet={3} mobile={3} textAlign="right">
          <Link onClick={signOutHandler} className={signOutLink}>
            <FaSignOutAlt style={signOutIconStyle} /> Sair
          </Link>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
